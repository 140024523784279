.n-voluntarios__ele-icono {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 1rem;
}

.n-voluntarios__user-avatar {
    width: 2em;
    max-height: 2em;
    object-fit: cover;
    border: 2px solid var(--color-secundario);
}

.n-voluntarios__item-user {
    font-size: .9em;
    text-transform: none;
}
