.diag-editor .modal-header{
    padding: 4px 8px;
}

.diag-editor .modal-title > span{
    margin-left: 15px;
}

.diag-editor .modal-header .close {
    margin: 0;
    padding: 0 6px;
}

.diag-editor .modal-header .close > span {
    font-size: 35px;
    font-family: monospace;
    font-weight: 800;
}

.modal-body{
    max-height: 80vh;
    overflow-x: auto;
}

.btn-perfil {
    float: left;
}

.modal-dialog .nav-tabs {
    margin-bottom: 1.6em;
    border-bottom: 2px solid #aaaaaa;
}

.modal-dialog .nav-tabs a {
    color: #777777;
}

.modal-dialog .nav-tabs a:hover {
    color: #000000;
}

.nav-tabs .nav-link:hover {
    border: none;
}

.nav-tabs .nav-link:hover.active,
.nav-tabs .nav-link.active {
    color: #495057;
    font-weight: bold;
    border: 2px solid #aaaaaa;
    border-color: #aaaaaa #aaaaaa var(--color_fondo_modal) #aaaaaa;
    margin-bottom: -2px;
    background: linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(255,255,255,1) 0%, rgba(243,243,243,1) 100%);
    border-radius: 8px 0 0 0;
}

.modal-dialog .explicacion {
    margin-bottom: 1.1em;
    line-height: 1.2em;
    text-transform: none;
}

.modal-dialog .zona-arrastre {
    overflow-y: auto;
    height: 40vh;
    max-height: 500px;
}

@media (max-width:992px){
    .modal-dialog {
        max-width: 90vw;
    }
}

.btn-footer-left {
    position: absolute;
    left: 1rem;
}

.cuentas-conectadas-listado-redes-sociales .cuentas-conectadas-nombre-cuenta {
    font-weight: bold;
}

