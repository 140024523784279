.container-btns-bibio p {
    display: flex;
}

.container-btns-bibio .icono {
    font-size: 2em;
    display: block;
    float: left;
    margin-right: 0.4em;
    margin-top: 0.3em;
}

.container-btns-bibio{
    max-height: 40vh;
    overflow-y: auto;
}

.btn-biblio{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.btn-biblio > button {
    height: 112px;
    width: 100%;
    line-height: 1.1em;
}

.btn-biblio i{
    font-size: 32px;
    margin-bottom: 10px;
    display: inline-block;
    line-height: normal;
}

.btn-biblio i.mdi {
    font-size: 42px;
    margin-bottom: 2px;
}

.btn-biblio .btn{
    font-size: 0.8rem;
}

.btn-biblio .btn > div{
    overflow: hidden;
    text-overflow: ellipsis;
}
