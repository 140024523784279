.contenedor-spinner-respuesta-servidor {
    width: 2rem;
    height: 2rem;
    position: absolute;
    bottom: 0.5rem;
    left: 0.5rem;
    z-index: 999;
}

.spinner-respuesta-servidor {
    display: block;
    margin: auto;
    width: 100%;
    height: 100%;
}
