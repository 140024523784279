:root {
    --color-principal: #FFCC00;
    --color-secundario: #1A1A1A;
    --color-terciario: #FFFFFF;
    --color-gris: #A9A9A9;
    --color-gris-oscuro: #5e5e5e;
    --color-principal-opuesto: --color-secundario;
    --color-secundario-opuesto: #FFFFFF;
    --color-rojo-destacado: #ff4444;

    /*--color-gris: #A3A3A3;*/

    --top-bar-height: 60px;
    --footer-height: 100px;
}

@media (min-width:1900px) {
    :root {
        --footer-height: 120px;
    }
}
