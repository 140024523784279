:root{
    --alto-cabecera-editor: 86px;
    --tamano-icono-cabecera-editor: 44px;
}

.cabecera{
    height: var(--alto-cabecera-editor);
}

.titulo{
    font-size: 28px;
    font-weight: bold;
}

.logo-editor{
    height: 50px;
}

.cabecera .nav-link {
    padding: 0;
}

.btn-cabecera-editor{
    display: flex;
    flex-direction: column;
}

.btn-cabecera-editor > .icono-cabecera-editor {
    display: table-row;
    margin: auto;
    height: calc(var(--tamano-icono-cabecera-editor) + 10px );
    width: var(--tamano-icono-cabecera-editor);
}

.btn-cabecera-editor > span {
    display: table-row;
    font-size: 15px;
    text-align: center;
}

.cont-btn-cabecera-edit{
    width: 100px
}

.cont-btn-cabecera-edit.disabled{
    pointer-events: none;
}

.cont-btn-cabecera-edit.disabled .btn-cabecera-editor{
    opacity: .5;
}

.cont-btn-cabecera-edit > a{
    margin: auto;
}

.cont-btn-cabecera-edit > a:after{
    content: none;
}

.cont-btn-cabecera-edit-users{
    width: 100px
}

.navbar-toggler {
    padding: 0.2em 0.3em;
}

.cabecera .titulo {
    overflow: hidden;
    max-width: calc(100% - 700px);
    text-overflow: ellipsis;
}

.radio-capas{
    pointer-events: none;
}

@media (max-width: 1199px) {

    .cabecera .icono-cabecera-editor{
        display: none;
    }

    .cabecera .cont-btn-cabecera-edit,
    .cabecera .cont-btn-cabecera-edit-users {
        border-top: 1px dashed var(--color-principal);
        padding: 0.6em;
        width: 100%;
    }

    .cabecera .titulo {
        overflow: hidden;
        max-width: calc(100vw - 180px);
        text-overflow: ellipsis;
        font-size: 3.4vw;
    }

}
