.contenedor-mapa-editor {
    position: absolute;
    overflow: hidden;
    width: calc(100% - var(--ancho_barra_izquierda_editor));
    height: calc(100% - var(--alto_subcabecera_editor));
    top: var(--alto_subcabecera_editor);
    left: var(--ancho_barra_izquierda_editor);
    transition: width var(--t_anim_barra_izq_edit) var(--f_anim_in_barra_izq_edit), left var(--t_anim_barra_izq_edit) var(--f_anim_in_barra_izq_edit);
}

.contenedor-mapa-editor-extended {
    left: 0;
    width: 100%;
    transition: width var(--t_anim_barra_izq_edit) var(--f_anim_out_barra_izq_edit), left var(--t_anim_barra_izq_edit) var(--f_anim_out_barra_izq_edit);
}

.algolia-places{
    z-index: 400;
    width: 15vw;
    left: 0;
    margin: 15px;
}
