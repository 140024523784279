.filtro-visibilidad {
    margin: 0;
    width: calc(100% - 39px);
}

.filtro-visibilidad > select {
    height: 100%;
    background-color: transparent;
    border: none;
    border-radius: 0;
    padding: 2px 10px;
}

.filtro-visibilidad > select:hover,
.filtro-visibilidad > select:focus,
.filtro-visibilidad > select:active {
    background-color: transparent;
}
