.heightgraph-container {
    margin-right: 150px;
    background-color: rgba(255, 255, 255, .25);
    border-radius: 0;
}

.heightgraph {
    margin-bottom: 0px !important;
    pointer-events: none;
}
