:root {
    --ancho_barra_herramientas_editor: 7vh;
    --t_anim_barra_herramientas_editor: 50ms;
    --f_anim_out_barra_herramientas_editor: ease-out;
    --f_anim_in_barra_herramientas_editor: ease-in;
}

.barra-herramientas {
    position: absolute;
    width: var(--ancho_barra_herramientas_editor);
    right: calc(-15px - var(--ancho_barra_herramientas_editor));
    top: calc(var(--alto_subcabecera_editor) + 15px);
    z-index: 500;
    transition: right var(--t_anim_barra_herramientas_editor) var(--f_anim_in_barra_herramientas_editor);
}

.barra-herramientas-visible {
    right: 15px;
    transition: right var(--t_anim_barra_herramientas_editor) var(--f_anim_in_barra_herramientas_editor);
}

.btn-barra-herramientas-editor {
    width: var(--ancho_barra_herramientas_editor);
    display: block;
    align-items: center;
    justify-content: center;
    height: var(--ancho_barra_herramientas_editor);
    border-radius: 6px;
    margin-bottom: 3px;
    border: none;
}

.barra-herramientas .btn-barra-herramientas-editor > div{
    position: relative;
    display: block;
    text-align: center;
    width: 100%;
    line-height: 1;
    margin-bottom: 5px;
}

.btn-barra-herramientas-editor > div > svg,
.btn-barra-herramientas-editor > div > i {
    font-size: 2rem;
}

.btn-barra-herramientas-editor > div > span {
    font-size: 0.75em;
}

.btn-herramientas__svg {
    width: 2.3rem;
}

@media (max-height: 850px) {
    .barra-herramientas{
        width: 62px;
    }
    .btn-barra-herramientas-editor {
        height: 62px;
    }
    .btn-barra-herramientas-editor > div > svg {
        font-size: .75em;
    }
    .btn-barra-herramientas-editor > div > span {
        font-size: .75em;
        line-height: 11px;
        display: block;
    }
    .btn-group-vertical > .btn, .btn-group-vertical > .btn-group {
        padding: 3px;
    }
}

