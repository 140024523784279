

.modal-subs {

}

.modal-subs__table {
    width: 100%;
}

.modal-subs__row {

}
.modal-subs__row:nth-child(even) {
    background: white;
}

.modal-subs__cell {
    padding: 10px;
}

.modal-subs__img {
    border-radius: 5px;
    border: 1px solid var(--color-secundario);
    width: 80px;
    height: 80px;
}

.modal-subs__btn {
    border-radius: 5px;
    width: 80px;
    height: 80px;
    display: block;
    overflow: hidden;
    background: var(--color-gris);
    color: white;
    font-size: 12px;
    line-height: 1.2;
    border: none;
}
.modal-subs__btn--selected {
    background: var(--color-principal);
    color: var(--color-secundario);
}

.modal-subs__price {
    font-size: 20px;
    font-weight: bold;
    margin-top: 10px;
    white-space: nowrap;
}


.modal-subs__info-btn {
    border-radius: 5px;
    width: 80px;
    height: 80px;
    display: block;
    overflow: hidden;
    background: var(--color-secundario);
    color: white;
    font-size: 12px;
    line-height: 1.2;
    border: none;
    text-align: center;

}

.modal-subs__info-plazo {
    font-size: 14px;
    font-weight: bold;
    margin-top: 10px;
    white-space: nowrap;
}

.modal-subs__importeTotal {
    font-size: 1.7em;
}

.modal-subs__table-wrapper {
    max-height: calc(80vh - 210px);
    overflow: auto;
}

.modal-subs__card-error {
    color: red;
    text-transform: initial;
    font-weight: bold;
}
