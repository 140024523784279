@font-face {
    font-family: "icons";
    src: url("./icons.eot?65628cbd2c0f4191af04854e62dd02fb?#iefix") format("embedded-opentype"),
url("./icons.woff2?65628cbd2c0f4191af04854e62dd02fb") format("woff2"),
url("./icons.woff?65628cbd2c0f4191af04854e62dd02fb") format("woff"),
url("./icons.ttf?65628cbd2c0f4191af04854e62dd02fb") format("truetype"),
url("./icons.svg?65628cbd2c0f4191af04854e62dd02fb#icons") format("svg");
}

i[class^="icon-"]:before, i[class*=" icon-"]:before {
    font-family: icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-alojamiento:before {
    content: "\f101";
}
.icon-ambulancia:before {
    content: "\f102";
}
.icon-at-mecanica:before {
    content: "\f103";
}
.icon-boxes:before {
    content: "\f104";
}
.icon-bus:before {
    content: "\f105";
}
.icon-cafeteria:before {
    content: "\f106";
}
.icon-camara-llamadas:before {
    content: "\f107";
}
.icon-cambio-sentido-drch:before {
    content: "\f108";
}
.icon-cambio-sentido-izq:before {
    content: "\f109";
}
.icon-carpa-cubierta:before {
    content: "\f10a";
}
.icon-centro-competicion:before {
    content: "\f10b";
}
.icon-control-crono:before {
    content: "\f10c";
}
.icon-corte:before {
    content: "\f10d";
}
.icon-cuenta-vueltas:before {
    content: "\f10e";
}
.icon-duchas:before {
    content: "\f10f";
}
.icon-entrega-dorsales:before {
    content: "\f110";
}
.icon-guardarropa-variant:before {
    content: "\f111";
}
.icon-guarderia:before {
    content: "\f112";
}
.icon-obstaculo:before {
    content: "\f113";
}
.icon-parking:before {
    content: "\f114";
}
.icon-pasos-publico:before {
    content: "\f115";
}
.icon-policia:before {
    content: "\f116";
}
.icon-punto-encuentro:before {
    content: "\f117";
}
.icon-serv-medicos:before {
    content: "\f118";
}
.icon-speaker:before {
    content: "\f119";
}
.icon-tablon-anuncios:before {
    content: "\f11a";
}
.icon-zona-penalizacion:before {
    content: "\f11b";
}
.icon-zona-seguimiento:before {
    content: "\f11c";
}
