:root {
    --ancho_barra_izquierda_editor: 250px;
    --t_anim_barra_izq_edit: 200ms;
    --f_anim_out_barra_izq_edit: ease-out;
    --f_anim_in_barra_izq_edit: ease-in;
}

.editor-barra-izquierda{
    position: relative;
    height: calc(100% - var(--alto_subcabecera_editor));
    width: var(--ancho_barra_izquierda_editor);
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 500;
    top: var(--alto_subcabecera_editor);
    left: 0;
    transition: left var(--t_anim_barra_izq_edit) var(--f_anim_in_barra_izq_edit);
}

.editor-barra-izquierda-cerrada{
    left: calc(0px - var(--ancho_barra_izquierda_editor));
    transition: left var(--t_anim_barra_izq_edit) var(--f_anim_out_barra_izq_edit);
}

.editor-barra-izquierda-vacia{
    display: flex;
    justify-content: center;
    align-items: center;
}

.editor-barra-izquierda .card-body {
    padding: 0;
}

.editor-barra-izquierda .texto {
    padding: 0;
}

.editor-barra-izquierda ul {
    padding: 0;
}

.editor-barra-izquierda .card-body > ul{
    padding: 0;
}

.editor-barra-izquierda .contenedor-mover {
    width: 2px;
    height: 13px;
}

.editor-barra-izquierda .mover,
.titulo-agrupacion .mover,
.titulo-agrupacion .editar{
    display: none;
    position: absolute;
    align-self: end;
    right: 4px;
    margin-top: 15px;
    background-color: var(--color-principal);
    padding: 3px;
    border-radius: 3px;
    color: var(--color-secundario);
    font-size: 0.9em;
}

.titulo-agrupacion .editar{
    right: 32px;
}

.titulo-agrupacion ul li .mover {
    margin-top: 3px;
}

.titulo-agrupacion .editar:hover{
    background-color: var(--color-secundario);
    color: var(--color-principal);
}

.editor-barra-izquierda .card-header:hover .mover,
.editor-barra-izquierda .card-header:hover .editar,
.titulo-agrupacion .card-body li:hover .mover,
.titulo-agrupacion .card-body li:hover .editar{
    display: block;
}

.editor-barra-izquierda .card-header:hover .editor-elemento-contenedor {
    background-color: #464646;
}

.editor-barra-izquierda svg {
    display: block;
    padding: 1px 1px;
    height: 24px;
    width: 24px;
    margin-right: 8px;
}

.editor-barra-izquierda ul li,
.editor-barra-izquierda .editor-elemento-contenedor {
    display: flex;
    align-items: center;
    padding: 0 3px;
}

.editor-barra-izquierda .editor-elemento-nombre{
    display: block;
    line-height: 1em;
    max-width: 73%;
    float: right;
}

.editor-barra-izquierda .titulo-agrupacion  ul {
    padding: 0.3em 0 0.3em 1.9em;
    margin-top: 0.4em;
    font-size: 0.8em;
}

.msg-nada-anadido{
    position: relative;
    width: 100%;
}

.ico-nada-anadido {
    position: relative;
    text-align: center;
    font-size: 5em;
}

.ico-nada-anadido svg {
    margin: 0 auto;
    height: inherit;
}

.txt-nada-anadido{
    text-align: center;
    font-size: 14px;
    padding: 0 1.5em;
}

.texto.seleccionado .editor-elemento-contenedor{
    background-color: #4c4c4c;
    font-weight: bold;
}


@media (max-width: 1200px) {
    :root {
        --ancho_barra_izquierda_editor: 200px;
        --alto-cabecera-editor: 75px;
    }

    .editor-barra-izquierda {
        font-size: 0.8em;
    }

    .editor-barra-izquierda .card-body {
        padding: 0.50rem;
    }
}
