.estado-proyecto__container {
    bottom: 1rem;
    z-index: 999;
    position: absolute;
}

.estado-proyecto__wrapper {
    background-color: var(--color_btn_shadow);
}

.estado-proyecto__flecha {
    color: var(--color-principal);
    font-size: 1.2rem;
}

.estado-proyecto__flecha--disabled {
    color: var(--color_fondo_modal_secundario)
}

.estado-proyecto__item-texto {
    display: block;
    color: var(--color-principal);
    font-size: 1.5em;
}

.estado-proyecto__item-punto {
    width: 20px;
    height: 20px;
    background-color: var(--color-principal);
}

.estado-proyecto__item--disabled > .estado-proyecto__item-texto {
    color: var(--color_fondo_modal_secundario)
}

.estado-proyecto__item--disabled > .estado-proyecto__item-punto {
    background-color: var(--color_fondo_modal_secundario);
}
