

.preview__backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.90);
    /*filter: blur(8px);*/
    z-index: 99999;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    overflow: scroll;
}


.preview__close-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 20px;
    right: 30px;
    border: none;
    font-size: 50px;
    line-height: 1;
    border-radius: 100%;
    width: 45px;
    height: 45px;
    background: var(--color-principal);
    z-index: 9999999;
}

.preview__centerbox {
    display: flex;
    flex-direction: column;
}


.preview__top-row {
    display: flex;
    flex-direction: row;
}


.preview__phone {
    background-position: 0 0;
    background-repeat: no-repeat;
    width: 446px;
    height: 755px;
    overflow: hidden;
    position: relative;
    min-width: 446px;
}

.preview__phone > iframe {
    border: none;
    position: absolute;
    left: 61px;
    right: 80px;
    width: 304px;
    top: 43px;
    bottom: 80px;
    height: 629px;
    overflow-y: scroll;
    overflow-x: hidden;
    border-bottom-left-radius: 35px;
    border-bottom-right-radius: 35px;
}

.preview__desktop {
    background-position: 0 0;
    background-repeat: no-repeat;
    overflow: hidden;
    position: relative;
    width: 1107px;
    height: 653px;
    min-width: 1107px;
}

.preview__desktop > iframe {
    border: none;
    position: absolute;
    left: 134px;
    right: 133px;
    width: 840px;
    top: 37px;
    bottom: 92px;
    height: 524px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.preview__bottom-row {
    text-align: center;
}

.preview__bottom-row .input-group {
    font-size: 2em;
    text-align: center;
    width: 100%;
    max-width: 22em;
    margin-left: auto;
    margin-right: auto;
}

.preview__url-input {
    /*font-size: 2em;*/
    width: 100%;
    max-width: 20em;
    text-align: center;
}

@keyframes opacityOnAndOff {
    0% {
        opacity: 0;
    }
    20%{
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}



.preview__copy-msg {
    opacity: 0;
}

.preview__copy-msg--blink {
    animation: opacityOnAndOff 4s normal forwards;
}
