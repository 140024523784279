.layout {
    margin: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.contenedor-central {
    position: fixed;
    width: 100%;
    height: calc(100% - var(--alto-cabecera-editor));
    bottom: 0;
    overflow: hidden;
}

.btn-add{
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 50px;
    margin-bottom: 50px;
    z-index: 9999;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    font-size: 30px;
}

@media (max-height: 958px){
    .btn-add{
        margin-right: 120px;
    }
}

.fondo-carga{
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    align-content: center;
    overflow: hidden;
}

.spinner-carga{
    width: 6rem;
    height: 6rem;
    display: block;
    margin: auto;
}


/* Tamaños "asas" Leaflet Editable */
.leaflet-vertex-icon {
    width: 16px !important;
    height: 16px !important;
    margin-left: -8px !important;
    margin-top: -8px !important;
}
.leaflet-middle-icon {
    width: 16px !important;
    height: 16px !important;
    margin-left: -8px !important;
    margin-top: -8px !important;
}
