
.stripe-end {
    display: flex;
    min-height: 500px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.stripe-end__icon {
    font-size: 64px;
}
.stripe-end--ok .stripe-end__icon {
    color: var(--color-principal);
}
.stripe-end--ko .stripe-end__icon {
    color: #FF4D4D;
}
.stripe-end__title {
    font-size: 32px;
    font-weight: bold;
}
.stripe-end__subtitle {
    font-size: 18px;
}

.stripe-end__back {
    font-size: 24px;
    border: none;
    margin-top: 10px;
}
