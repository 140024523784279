
.fila-eliminado {
    background-color: #fbd5d4;
    color: #b72d00;
}

/*
.tabla-organizar-recorridos > tbody > tr {
    font-size: 14px;
}

.tabla-organizar-recorridos > thead > tr {
    font-size: 12px;
    text-transform: uppercase;
}

.tabla-organizar-recorridos > thead > tr > th,
.tabla-organizar-recorridos > tbody > tr > td {
    padding: 5px;
    border-top: none;
}

.tabla-organizar-recorridos > thead > tr > th{
    border-bottom: none;
}

.tabla-organizar-recorridos {
    margin: 0;
}
*/
