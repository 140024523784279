.modal-recorrido {
    margin-bottom: 2px;
}

.modal-recorrido .modal-recorrido-titulo {
    background-color: var(--color-secundario);
    color: var(--color-secundario-opuesto);
    margin: 1px;
}

.modal-recorrido .modal-recorrido-tabla{
    background-color: var(--color-terciario);
}

.modal-recorrido .table td,
.modal-recorrido .table th {
    padding: 3px 0;
    border-top: none;
}

.modal-recorrido .table tbody{
    margin-top: 5px;
}

.modal-recorrido .table thead th {
    border-bottom: 2px solid var(--color-secundario);
}

.modal-recorrido .table tbody tr:first-child td{
    padding-top: 19px;
}

.modal-recorrido .table tbody tr td:first-child{
    padding-left: 10px;
}

.modal-recorrido .table tbody tr:nth-child(even) {
    background-color: var(--color_fondo_modal);
}

.modal-recorrido-distancia-homologada.form-group {
    margin-bottom: 0;
}

.modal-recorrido-distancia-homologada input{
    width: 80%;
    max-width: 6em;
    float: left;
    margin-right: 0.3em;
    height: 1.7em;
}

.modal-recorrido-unidades {
    text-transform: none;
}

.previsualizacion-imagen{
    max-width: 100%;
    max-height: 100%;
}

.fondo-icono-select-serv{
    color: white;
    font-size: 16px;
    margin-right: 15px;
}

.row-sector-pk {
    padding-left: 1rem;
}

.celda-servicios .icono-servicio > i {
   display: flex;
   align-items: center;
}

.avanzado__user-avatar {
    width: 3em;
    max-height: 3em;
    object-fit: cover;
    border: 2px solid var(--color-secundario);
}
