.input-group-propiedad-color {
    max-width: 100px;
}

.input-group-propiedad-color-xl {
    max-width: 175px;
}

.input-group-propiedad input.input-color-muestra{
    width: calc(var(--alto_subcabecera_editor) - 10px);
    height: calc(var(--alto_subcabecera_editor) - 10px);
    border: 1px solid #000000;
    padding: 0;
    border-radius: 6px;
    overflow: hidden;
}

input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
}
input[type="color"]::-webkit-color-swatch {
    border: none;
}
