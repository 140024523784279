.explicacion-secuencia-recorrido{
    font-size: 13px;
    padding-top: 0;
}

.display-trayecto-crear-recorrido{
    padding: 5px 10px;
    margin-bottom: 5px;
    cursor: pointer;
    font-size: 14px;
}

.display-trayecto-crear-recorrido > .nombre-trayecto {
    text-align: center;
}

.display-trayecto-crear-recorrido > .distancia-trayecto {
    text-align: center;
}

.contenedor-lista-sectores{
    width: 100%;
    min-height: 300px;
    padding: 1px 4px;
}

.contenedor-lista-sectores.vacia {
    display: flex;
    align-items: center;
    align-content: center;
}

.contenedor-lista-sectores.vacia > .msg {
    text-align: center;
    width: 100%;
}

.tabla-trayectos-sector > tbody > tr {
    font-size: 14px;
    border-bottom: 2px solid var(--color-gris);
}

.tabla-trayectos-sector > thead > tr {
    font-size: 12px;
    text-transform: uppercase;
}

.tabla-trayectos-sector > thead > tr > th,
.tabla-trayectos-sector > tbody > tr > td {
    padding: 5px;
    border-top: none;
}

.tabla-trayectos-sector > thead > tr > th{
    border-bottom: none;
}

.tabla-trayectos-sector {
    margin: 0;
}

.tabla-trayectos-sector .basura-trayecto{
    cursor: pointer;
}

.tabla-trayectos-sector .titulo-trayecto {
    padding: 0 5px;
}

.tabla-trayectos-sector .input-repeticiones {
    width: 40px;
    font-size: 14px;
    padding: 0 0 0 5px;
    border: none;
}

.recorrido-opciones-avanzadas {
    border: none;
    background-color: transparent;
    word-wrap: inherit;
}

.recorrido-opciones-avanzadas > .card-header {
    margin: 0;
    border: 0;
    background-color: transparent;
    padding: 0 0 .75rem;
    cursor: pointer;
}

.recorrido-opciones-avanzadas .card-body {
    padding: 0;
}

.titulo-avanzadas {
    font-weight: 700;
    font-size: 1.1em;
    background-color: var(--color-secundario);
    padding: 0.3em 0.6em;
    color: var(--color-terciario);
    border-radius: 6px;
}

.titulo-avanzadas > svg.fa-chevron-down {
    float: right;
    margin-top: 4px;
}

.titulo-avanzadas > svg.fa-plus-circle {
    margin-right: 0.5em;
}

.encabezado-sector {
    font-size: 12px;
    margin-top: 20px;
}

.encabezado-sector:first-child {
    margin-top: 0;
}

.encabezado-sector .icono-eliminar{
    cursor: pointer;
    font-size: 27px;
    background-color: var(--color-secundario);
    color: var(--color-principal);
    align-self: center;
    padding: 5px 12px;
    display: block;
    text-align: center;
    border-radius: 6px;
    position: absolute;
    top: 10px;
    transition: color 0.2s linear;
}

.encabezado-sector .icono-eliminar:hover{
    color: #e63a3a;
}

.encabezado-sector .distancias {
    font-size: 1.1em;
}

.encabezado-sector .titulo {
    font-size: 18px;
    text-align: left;
    line-height: 1;
}

.encabezado-sector .titulo.anadir {
    cursor: pointer;
}

.encabezado-sector .titulo.anadir > svg {
    margin-right: 14px;
}

.titulo-distancias-totales{
    margin-top: 30px;
    text-align: right;
}

.titulo-distancias-totales .valor{
    font-weight: bold;
}

.input-nombre-sector {
    margin-bottom: 10px;
    padding: 3px 6px;
    line-height: 1;
}

.acordeon-anadir-pdis{
    margin-top: 20px
}

.contenedor-perfil_sizer {
    background: white;
    border-radius: 6px;
    padding-top: 86%; /* relación de aspecto */
    position: relative;
    height: 300px;
}

.contenedor-perfil {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}

.celda-servicios {
    display: flex;
}

.icono-servicio {
    display: flex;
    padding: 6px 12px;
    margin: 2px;
    height: 36px;
}

.icono-servicio > i {
    font-size: 16px;
}

.icono-mas-servicio {
    cursor: pointer;
}

.contenedor-mapa-vista-inicial {
    width: 100%;
    height: 50vh;
}

.btn-restablecer-vista {
    position: absolute;
    z-index: 500;
    right: 1vw;
    top: 1vw;
}

.modal .nav-tabs .nav-link {
    padding: 0.4rem 0.5rem;
}

.col-spinner{
    overflow: hidden;
}

.spinner-respuesta-recorrido{
    width: 6rem;
    height: 6rem;
    margin: auto;
}

/** Secuencia de sectores **/

.modal-secuenciaSectores {
    margin-bottom: 1em;
}

.modal-secuenciaSectores .nav-pills{
    width: 100%;
}

.modal-secuenciaSectores .nav-pills .col{
    padding: 5px;
}

.modal-secuenciaSectores .nav-link{
    font-size: 0.8em;
    line-height: 1em;
    text-align: center;
}

.modal-secuenciaSectores .nav-pills .nav-link {
    background-color: #cccccc;
}

.modal-secuenciaSectores .nav-pills .nav-link:hover {
    color: var(--color_texto_secundario);
    background-color: var(--color_fondo_secundario);
}

.modal-secuenciaSectores .nav-pills .nav-link.active {
    background-color: var(--color_fondo);
}

.modal-secuenciaSectores .nav-pills .nav-link.active:hover {
    color: var(--color_texto_sobre_fondo);
}

