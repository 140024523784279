.input-group-propiedad{
    padding: 5px 5px;
    font-size: 14px;
}

.input-group-propiedad .input-group-text {
    background-color: transparent;
    border: none;
    font-size: 14px;
    height: calc(var(--alto_subcabecera_editor) - 10px);
}

.input-group-propiedad > input{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    font-size: 14px;
    height: calc(var(--alto_subcabecera_editor) - 10px);
}

@media (max-width: 1200px) {
    .input-group-propiedad .input-group-text {
        height: 12px;
        display: block;
        padding-top: 0;
    }
    .input-group-propiedad-select .input-group-text,
    .input-group-propiedad-color .input-group-text{
        display: none;
    }
}
