.lista-formas {
    list-style: none;
    padding: 15px;
}

.lista-formas > li {
    width: 100%;
    padding: 5px 15px;
    background-color: var(--color-secundario);
    color: var(--color-principal);
    cursor: pointer;
    margin-bottom: 5px;
}

.lista-formas > li:hover,
.lista-formas > li:active {
    background-color:var(--color-principal);
    color: var(--color-principal-opuesto);
}

.medida {
    float: right;
}
