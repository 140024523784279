.input-group-propiedad-distancia-real {
     max-width: 230px;
}

.input-group-propiedad-distancia-real > span {
    padding: 6px 3px
}

.input-group-propiedad-distancia-real > div > span {
    padding: 6px 3px 6px 12px;
}