.editor-card{
    border: none;
    background-color: transparent;
}

.editor-acordeon-header {
    border:none;
    border-radius: 0 !important;
    font-weight: bold;
    cursor: pointer;
    display: flex;
}

.editor-acordeon-header button{
    background-color: transparent;
    color: inherit;
    border: none;
    outline: none;
    margin-right: 5px;
    width: 30px;
}

.editor-acordeon-body{
    background-color: transparent;
}

.editor-acordeon-body ul {
    margin: 0;
    padding: 0 0 0 10px;
    list-style: none;
    margin-bottom: 0.5em;
}

.editor-acordeon-body ul>li {
    cursor: pointer;
    line-height: 1.1em;
}

.editor-acordeon-body ul button {
    background-color: transparent;
    color: inherit;
    border: none;
    outline: none;
    margin-right: 5px;
    width: 30px;
}

.editor-acordeon-marca-color{
    width: 12px;
    height: 12px;
    display: inline-block;
    margin: 0 6px 0 0;
    border-radius: 100%;
}

.titulo-agrupacion {
    display: flex;
}

.titulo-agrupacion ul {
    background-color: #4c4c4c;
    width: 100%;
}

.titulo-agrupacion ul li:hover{
    background-color: #646464;
}

.titulo-agrupacion .mover {
    margin-bottom: 3px;
}

.titulo-agrupacion > span:first-child {
    display: inline-block;
}

.titulo-agrupacion > .texto {
    margin-left: 25px;
    display: inline-block;
}

.titulo-agrupacion .editor-carpeta {
    color: var(--color-principal);
}

.acordeon-agrupaciones {
    width: 100%;
}

.acordeon-agrupaciones > .card{
    background-color: transparent;
    border: 0;
    margin-left: 0;
}

.acordeon-agrupaciones .card-header {
    margin-left: 0;
}

.titulo-agrupacion .editor-carpeta.texto-high,
.texto-high {
    background-color: var(--color-principal);
    color: var(--color-secundario);
}


