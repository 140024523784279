.display-distancias{
    position: absolute;
    bottom: 15px;
    left: calc(var(--ancho_barra_izquierda_editor) + 15px);
    width: 250px;
    pointer-events: none;
    opacity: 0;
    z-index: 1000;
    padding: 10px;
}

.display-distancias-visible{
    opacity: 1;
}

.display-distancias-desplazado{
    left: 15px;
}

.display-distancias-titulo{
    text-align: center;
}

.display-distancias-ul{
    list-style: none;
    padding: 0;
    font-size: 12px;
    margin-bottom: 0;
}

.input-group-propiedad-coord{
    max-width: 125px;
}