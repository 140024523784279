.lista-eliminar-recorridos ul{
    list-style: none;
}

.lista-eliminar-recorridos > div > ul > li{
    margin-bottom: 1em;
}

.mensaje {
    font-size: 1.2em;
    font-weight: bold;
    margin: 0.5em 0 1em;
    display: block;
}

.nombre-recorrido {
    font-weight: bold;
    display: block;
    background-color: var(--color-secundario);
    color: var(--color-secundario-opuesto);
    padding: 2px 10px;
    border-radius: 4px;
}

.tambien-eliminar {
    font-size: 0.8em;
}

ul.lista-subelementos {
    list-style-type: disclosure-closed;
    font-size: 0.9em;
}
